// Custom palette
@use "palette";
@use "@angular/material" as mat;
@import "~quill/dist/quill.snow.css";
@include mat.core();
/* @include angular-material-typography($custom-typography); */

$app-primary: mat.define-palette(palette.$custom-primary, 500);
$app-accent: mat.define-palette(palette.$custom-accent, 50, 500);
$app-warn: mat.define-palette(palette.$custom-warn);
$app-success: mat.define-palette(palette.$custom-success);
$app-typography: mat.define-typography-config(
  $headline-6: mat.define-typography-level(16px, 0, 400, Roboto, 0.15px),
  $subtitle-1: mat.define-typography-level(16px, 0, 600, Roboto, -0.06px),
  $subtitle-2: mat.define-typography-level(14px, 0, 500, Roboto, 0.06px),
);

// Create the theme
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $app-typography,
  )
);

//Typography

// Include the theming styles
@include mat.all-component-themes($app-theme);

.warning-text {
  color: mat.get-color-from-palette($app-warn);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: mat.font-family($app-typography);
}

h2 {
  font-family: mat.font-family($app-typography, headline-6);
  font-size: mat.font-size($app-typography, headline-6);
  font-weight: mat.font-weight($app-typography, headline-6);
  font-style: normal;
}

h3 {
  font-family: mat.font-family($app-typography, subtitle-1);
  font-size: mat.font-size($app-typography, subtitle-1);
  font-weight: normal;
  font-style: normal;
}

h4 {
  font-family: mat.font-family($app-typography, subtitle-2);
  font-size: mat.font-size($app-typography, subtitle-2);
  font-weight: mat.font-weight($app-typography, subtitle-2);
  font-style: normal;
}

.google-btn {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  width: 250px;
  height: 42px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  border: thin solid #888;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .google-icon-wrapper {
    width: 40px;
    height: 40px;
    .google-icon {
      width: 40px;
      height: 40px;
    }
  }
  .btn-text {
    float: right;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: bold;
  }
}

.microsoft-btn {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  width: 250px;
  height: 42px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  border: thin solid #888;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .microsoft-icon-wrapper {
    margin-right: 0.5rem;
    width: 25px;
    height: 25px;
    .microsoft-icon {
      width: 25px;
      height: 25px;
    }
  }
  .btn-text {
    float: right;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: bold;
  }
}

.sidenav-bottom-buttons button {
  font-size: mat.font-size($app-typography, headline-6);
  font-family: mat.font-family($app-typography, headline-6);
  font-weight: mat.font-weight($app-typography, headline-6);
  font-style: normal;
}

// Common page styles
.page-container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding: 20px 0;
    margin-bottom: 20px;

    h1 {
      margin: 0;
    }
    .header-action-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }
}

.hint-text {
  font-size: 0.8rem;
  color: #888;
}

.mat-mdc-menu-panel {
  max-width: 500px !important;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .no-data-text {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.warning-card {
  background-color: #fff3cd !important;
  //color: ;
}

.success-card {
  background-color: #d4edda !important;
}

.success-chip {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

.info-text {
  color: rgb(0, 119, 255);
  cursor: pointer;
}

.fullscreen-dialog {
  max-width: auto !important;
  max-height: auto !important;
  background-color: rgba($color: #000000, $alpha: 0.6) !important;
}

.fullscreen-dialog-content {
  max-height: 85vh;
}

// Global radio buttons override Material theme
.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: map-get(palette.$custom-accent, 900) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: map-get(palette.$custom-accent, 900) !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: map-get(palette.$custom-accent, 900) !important;
}

.mdc-button .mdc-button__label {
  display: flex;
  align-items: center;
}

.print-out {
  border: none;
  padding: 0.5in;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  cursor: pointer;

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #898989;
    border-radius: 50px;
  }
}
.ql-editor {
  height: auto;
  overflow-y: visible;
}

table .mat-mdc-header-row {
  line-height: normal;
}
